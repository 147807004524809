.Tasks {
    height: 98%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: rgba(233, 233, 233, 0);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
        background-color: #1869ff84;
    }
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        // background-color: rgba(170, 170, 170, 0);
        border: 1.5px solid rgb(169, 167, 167);
        border-radius: 2px;
    }
}
