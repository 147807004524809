// ---------------------------------------- GENERAL SYTLES
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    overflow-x: hidden;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
}
a {
    color: white;
    text-decoration: none;
}
ul {
    list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
// ---------------------------------------- FONTS
/* GT Walsheim Pro */
@font-face {
    font-family: "GT Walsheim Pro";
    src: local("GT Walsheim Pro Regular"), local("GT-Walsheim-Pro-Regular"),
        url("./assets/fonts/GT/GTWalsheimPro-Regular.woff2") format("woff2"),
        url("./assets/fonts/GT/GTWalsheimPro-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
/* Sailec */
@font-face {
    font-family: "Sailec";
    src: local("Sailec"), local("Sailec"),
        url("./assets/fonts/Sailec-Medium.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}
/* Avenir */
@font-face {
    font-family: "Avenir";
    src: local("Avenir-Roman"), local("Avenir-Roman"),
        url("./assets/fonts/Avenir/Avenir-Roman.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}
/* Lora or Butler */
@font-face {
    font-family: "Butler";
    src: url("./assets/fonts//Butler/Butler.woff2") format("woff2"),
        url("./assets/fonts/Butler/Butler.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Butler";
    src: url("./assets/fonts//Butler/Butler-Light.woff2") format("woff2"),
        url("./assets/fonts//Butler/Butler-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Butler";
    src: url("./assets/fonts//Butler/Butler-UltraLight.woff2") format("woff2"),
        url("./assets/fonts//Butler/Butler-UltraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}
