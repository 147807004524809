@import "../../variables.scss";

.FormContainer {
    width: 800px;
    min-height: 700px;
    background-color: #f8fafa;
    padding: 50px 80px 55px 80px;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgba(199, 205, 216, 0.7);
    box-shadow: 0 4px 0 4px rgb(174, 178, 186);
    box-shadow: 5px 5px 1px rgba(52, 53, 56, 0.3);
    box-shadow: 3px 3px 1px #1869ff;
    box-shadow: 3px 3px 3px 0px #1869ff9a;
    box-shadow: 0 4px 10px 0 rgba(85, 87, 91, 0.4);

    border: 1px solid rgb(213, 217, 218);
    position: relative;
    font-family: $gt;

    .CloseButton {
        position: absolute;
        top: 22px;
        right: -10px;
        font-size: 24px;
        font-weight: bold;

        svg {
            font-weight: bold;
            color: black;
        }

        &:hover {
            svg {
                cursor: pointer;
                color: #1869ff;
            }
        }
    }

    .CheckmarkContainer {
        width: 100%;
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-family: $dm;
            font-size: 18px;
            text-align: center;
            margin-top: 20px;
            color: #2d9fe6;
        }
    }
    .SpinnerContainerLarge {
        height: calc(100% - 105px);
        width: calc(100% - 160px);
        background-color: rgba(255, 255, 255, 0.85);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1001;
    }

    button {
        outline: none;
        border: none;
        background: none;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
        }
    }

    h1 {
        margin: 0;
        padding: 0;
        font-size: 26px;
    }

    h4 {
        font-family: $dm;
        font-weight: 400;
        font-size: 14px;
        background-color: #e8edf1e0;
        padding: 12px 25px;
        margin: 10px 0;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    .Error {
        font-size: 11px;
        color: orangered;
        margin-top: 3px;
        font-weight: 400;
    }

    form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;

        .AddRepoButton {
            border: 0.5px solid grey;
            border-radius: 3px;
            font-family: $gt;
            background-color: white;
            height: 25px;
            font-size: 13px;
            width: 140px;
            border: 0.5px solid rgb(198, 202, 208);
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-top: 10px;
            // justify-content: center;

            &:hover {
                background: #9cd6ff;
                transition: background 0.15s linear;
            }

            .AddRepo {
                font-size: 22px;
                font-weight: 500;
                color: black;
                margin-right: 5px;
                margin-left: 7px;
            }
        }
        .RemoveGithubInputFieldButton {
            height: 25px;
            width: 25px;
            border-radius: 2px;
            margin-top: 2px;

            svg {
                width: auto;
                margin: 0;
                margin-left: 4px;
                color: rgb(47, 47, 49);
                transition: background-color 0.2s;
            }
        }
        .RemoveGithubInputFieldButton:hover {
            background-color: rgb(231, 234, 236);
            box-shadow: none;
        }

        label {
            display: block;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 12px;
            position: relative;

            span {
                color: rgb(169, 176, 187);
                font-weight: 400;
                font-size: 11px;
                margin-left: 10px;
                font-family: $dm;
            }

            #ObligatoryFieldMark,
            #ObligatoryFieldMarkGit,
            #ObligatoryFieldMarkLogo {
                position: absolute;
                color: #1869ff;
                font-size: 14px;
                left: 70px;
                font-weight: bold;
            }
            #ObligatoryFieldMarkGit {
                left: 67px;
            }
            #ObligatoryFieldMarkLogo {
                left: 65px;
            }

            input {
                height: 30px;
                width: 100%;
                border: 0.5px solid rgb(198, 202, 208);
                border-radius: 3px;
                background: #f8fafa;
                margin-top: 4px;
                padding: 0 10px;
                color: #1869ff;
                font-family: $dm;
                outline: none;

                &:focus {
                    border: 1.7px solid #35a9f1;
                }
            }

            textarea {
                outline: none;
                padding: 5px;
                color: #1869ff;
                font-family: $dm;
                margin-top: 4px;
                width: 100%;
                height: 120px;
                border: 0.5px solid rgb(198, 202, 208);
                border-radius: 3px;
                background: #f8fafa;
            }
            ul {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                width: 100%;
                list-style: none;
                column-gap: 10px;
                row-gap: 5px;
                margin-top: 4px;

                li {
                    position: relative;
                    height: 23px;
                    border-radius: 4px;

                    &:hover {
                        background-color: rgba(216, 222, 230, 0.8);
                    }

                    label {
                        border-radius: 4px;
                        border: 1px solid rgba(216, 222, 230, 0.4);
                        background-color: rgba(216, 222, 230, 0.4);
                        cursor: pointer;
                        text-align: center;
                        font-weight: 400;
                        font-size: 10px;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        padding-top: 5px;
                    }
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"]:checked + label {
                        background-color: #35a9f1;
                        color: white;
                    }
                }
            }
            .SpinnerContainer {
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
                padding-right: 20px;
            }
            .PreviewContainer {
                height: 120px;
                max-width: 100%;
                text-align: center;
                margin: 15px auto 10px auto;
                border-radius: 4px;
                overflow: hidden;

                img {
                    object-fit: contain;
                    height: 100%;
                    max-width: 100%;
                    border-radius: 4px;
                }
            }
            .UploadLogoInput {
                height: 170px;
                border: 2.5px dashed rgb(218, 223, 226);
                background-color: rgba(216, 222, 230, 0.2);
                margin-top: 5px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .UploadLogo {
                font-family: $gt;
                background-color: white;
                font-size: 13px;
                padding: 5px 10px;
                font-weight: 300;
                border: 0.5px solid rgb(198, 202, 208);
                cursor: pointer;
                margin: 0 auto;
                margin-top: 10px;
                text-align: center;
                width: 170px;
                border-radius: 3px;
                transition: background 0.15s linear;

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                        rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                        rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
                    background: #9cd6ff;
                    transition: background 0.15s linear;
                    border-color: #5cbbff;
                }
            }
        }
        .CreateButton {
            text-align: center;

            input[type="submit"] {
                background-color: #3180ee;
                border: none;
                color: white;
                font-size: 15px;
                font-family: $gt;
                padding: 7px 60px;
                border-radius: 3px;
                margin: 0 auto;
                outline: none;
                cursor: pointer;

                &:hover {
                    background-color: #5e9ef7;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                        rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                        rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
                }
            }
        }
    }
}
