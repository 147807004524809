@import "variables.scss";

.MenuWrapper {
    height: 100vh;
    width: 10%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    padding-top: 3%;
    padding-right: 3.5vw;
    text-align: right;

    .Menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        position: relative;

        .VerticalLine {
            height: 100%;
            position: absolute;
            top: 0;
            right: -19px;

            &::before {
                content: "";
                height: 40px;
                width: 5px;
                position: absolute;
                background-color: rgb(25, 26, 29);
                bottom: 0;
                right: -2px;
            }
        }
        #FirstLink {
            padding-top: 0;
        }
        .TimelineLink {
            margin-top: -2px;
            height: 50px;
            cursor: pointer;
            transition: all 0.2s linear;
            text-align: right;
            font-size: 11px;

            .Active {
                font-weight: 600;
                color: $fc-secondary-l;
                position: relative;
                transition: all 0.3s linear;
                font-size: 16px;
            }
            .Active::before {
                transition: all 3s linear;
                content: "";
                position: absolute;
                right: -20.2px;
                top: -8px;
                width: 3.5px;
                height: 40px;
                background-color: $blue-l;
            }

            a {
                font-family: $po;
                font-family: $dm;
                font-size: 14px;
                font-weight: 500;
                color: $fc-primary-l;

                &:hover {
                    color: #1869ff;
                    transition: color 0.2s linear;
                }
            }
        }

        .Timeline {
            display: flex;
            flex-direction: column;
            align-items: right;
            font-family: "DM Sans";

            ul {
                list-style: none;

                li {
                    text-align: right;
                    cursor: pointer;
                    font-family: $gt;
                    font-size: 13px;
                    margin: 5px 0;

                    .Active {
                        color: $fc-secondary-l;
                        position: relative;
                        transition: color 0.3s linear;
                    }
                    .Active::before {
                        transition: background-color 3s linear;
                        content: "";
                        position: absolute;
                        right: -20.2px;
                        top: -8px;
                        width: 3.5px;
                        height: 40px;
                        background-color: $blue-l;
                    }

                    a {
                        color: $fc-primary-l;

                        &:hover {
                            color: #1869ff;
                            transition: color 0.2s linear;
                        }
                    }
                }
            }
        }

        .Count {
            font-family: "DM Sans";
            font-size: 10px;
            margin-top: auto;
            margin-bottom: 28px;
        }
    }
    .DarkMode {
        .VerticalLine {
            &::before {
                background-color: #dae0e8;
            }
        }
        .TimelineLink {
            .Active {
                font-weight: 500;
                color: $blue-l;
            }
            .Active::before {
                background-color: $blue-l;
            }

            a {
                font-weight: 200;
                color: $line-primary-l;

                &:hover {
                    color: $blue-l;
                }
            }
        }
        .Timeline {
            ul {
                li {
                    .Active {
                        color: #f7f9f9;
                    }
                    .Active::before {
                        background-color: $blue-l;
                    }
                    div {
                        color: #a0a7a7;
                        font-weight: 300;

                        a {
                            font-weight: 300;
                            color: #f7f9f9;

                            &:hover {
                                color: $blue-l;
                                transition: color 0.1s linear;
                            }
                        }
                    }
                }
            }
        }

        .Count {
            color: #dae0e8;
        }
    }
}
