@import "variables.scss";

.MainWrapper {
    width: 100vw;
    position: relative;
    background-color: rgb(247, 245, 245);
    transition: background-color 0.3s linear;

    .DarkModeButtonWrapper,
    .LineContainer {
        position: fixed;
        margin-top: 3%;
        z-index: 100;
    }
    .DarkModeButtonWrapper {
        right: 16.2%;
    }

    &.DarkMode {
        transition: background-color 0.2s linear;
        background-color: $bcg-primary-d;
        background-color: #16161d;
    }

    .ProjectFormContainer {
        position: fixed;
        top: 4%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 100;
    }
}
