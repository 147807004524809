@import "variables.scss";

.ProjectsWrapper {
    height: 100vh;
    width: 100vw;
    padding: 3.5% 8%;
    // background-color: rgb(242, 245, 247);
    background-color: rgb(222, 227, 231);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .ChevIcon {
        position: absolute;
        bottom: 4%;
        left: 47%;

        svg {
            margin: 0;
            width: 30px;
            height: 30px;
            padding: 4px;
            border-radius: 50%;
            padding-top: 6px;
            transition: border-color 0.2s linear;
            border: 1px solid white;

            &:hover {
                border-color: #1869ff;
                transition: border-color 0.2s linear;
            }
        }
    }

    .Projects {
        width: 100%;
        padding-right: 8%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Greeting {
            margin-top: -10px;
            font-family: $gt;
            color: $fc-secondary-l;
            font-size: 14px;
            align-self: flex-start;
            text-align: left;
        }
        .Heading {
            text-align: center;
            margin-top: 7%;
            margin-bottom: 2%;
            display: flex;
            width: 100%;
            // background-color: rgb(164, 204, 164);
            position: relative;

            .ChevIcon {
                position: absolute;
                bottom: 50%;
                transform: translateY(50%);

                svg {
                    transform: rotate(90deg);
                    margin: 0;
                    width: 30px;
                    height: 30px;
                    padding: 4px;
                    border-radius: 50%;
                    background-color: rgb(80, 81, 81);
                    padding-top: 6px;
                    transition: background-color 0.3s linear;

                    &:hover {
                        background-color: black;
                        transition: background-color 0.3s linear;
                    }
                }
            }
            #Left {
                left: -70px;
            }
            #Right {
                right: -70px;
                svg {
                    transform: rotate(270deg);
                }
            }

            .ProjectCircle {
                width: 130px;
                height: 130px;
                margin: 0 30px;
                border-radius: 50%;
                cursor: pointer;
                padding: 30px;
                padding-top: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: border-color 0.3 linear;
                transition: all 0.3s ease-out;

                &:hover img {
                    transition: all 0.3s ease-in-out;
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }

                img {
                    height: 50%;
                    object-fit: contain;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                }
            }
            #sinkplant {
                margin-top: -19px;
                margin-right: 80px;

                img {
                    height: 116%;
                }
            }
            #nap {
                margin-right: 20px;
            }
            #portfolio {
                margin-top: -10px;
                margin-right: 0;
                img {
                    height: 54%;
                }
            }
            #lifetracker {
                margin-top: -6px;
                img {
                    height: 52%;
                }
            }
        }
        .ProjectDetails {
            .ProjectTitle {
                height: 115px;
                display: flex;
                align-items: flex-end;
                // padding-bottom: 10px;

                #lifetracker {
                    height: 40px;
                }

                .EditDeleteProject {
                    position: relative;

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background-color 0.1s linear;
                        width: 25px;
                        height: 25px;
                        border-radius: 3px;
                        cursor: pointer;
                        border: 0.1px solid white;
                        background-color: #fff;
                        margin-right: 10px;

                        &:hover {
                            color: #1869ff;
                            border-color: #1869ff;
                            transition: all 0.1s linear;
                        }

                        svg {
                            width: auto;
                            margin: 0;
                        }
                    }

                    .PasswordModalContainer {
                        position: absolute;
                        top: -260px;
                    }
                }

                .LogoContainer,
                .LogoContainerHorizontal {
                    display: flex;
                    justify-content: baseline;
                    height: 80px;
                    overflow: hidden;

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .LogoContainer {
                    height: 50px;
                }
                .LogoContainerHorizontal {
                    height: 100px;
                }
            }
            .Description {
                margin-top: 20px;
                font-family: $gt;
                p {
                    font-size: 14px;
                }
            }
            .SecondColumn {
                position: relative;

                &:hover .Border {
                    border-color: snow;
                    transition: border-color 0.2s linear;
                }

                .Border {
                    position: absolute;
                    left: -10%;
                    top: 70px;
                    width: 120%;
                    min-height: 320px;
                    border: 1px solid rgba(255, 255, 255, 0);
                    border-radius: 5px;
                }
                .LastUpdated {
                    font-family: $gt;
                    font-weight: 500;
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 3px;
                }
                h6 {
                    font-family: $dm;
                    font-weight: 600;
                    font-size: 13px;
                    margin-top: 10px;
                }

                .IconsWrapper {
                    display: flex;
                    max-width: 100%;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    justify-content: space-between;

                    span {
                        padding: 0;
                        font-size: 34px;
                        margin-right: 10px;
                        margin-bottom: 12px;

                        svg {
                            margin: 0;
                            padding: 0;
                            width: auto;
                        }
                    }
                }
                .ProjectButtons {
                    display: flex;
                    justify-content: space-between;

                    .StandardButton {
                        z-index: 100;
                        outline: none;
                        margin-top: 15px;
                        padding: 2px 5px;
                        width: 115px;
                        height: 30px;
                        font-family: $gt;
                        cursor: pointer;
                        border-radius: 5px;
                        border: 1.5px solid #28292a;
                        background: white;
                        transition: background 0.2s linear;
                        box-shadow: 3px 3px 1px #1869ff;

                        span {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            svg {
                                font-size: 18px;
                                width: auto;
                            }
                        }

                        &:hover {
                            color: #1869ff;
                            border-color: #1869ff;
                            transition: all 0.2s linear;
                        }
                    }
                }
            }
        }

        .NoProjectsMessage {
            margin-left: 18px;
            margin-top: 15%;
            height: 100%;
            font-family: $dm;
            font-weight: 600;
            color: rgba(158, 162, 162, 0.5);
            font-size: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            svg {
                margin-top: 30px;
            }
        }
    }
}

.DarkMode {
    background-color: #1a1c20;
    background-color: #0f0f16;
    .Projects {
        .Greeting {
            color: $fc-primary-d;
            font-weight: 300;
            letter-spacing: 0.6px;
        }
    }
}
