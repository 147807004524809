@import "variables.scss";

.WithBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;

    .ImageModalWrapper {
        position: relative;
        width: 80%;
        height: 96%;
        background-color: white;
        border-radius: 5px;
        padding: 20px 30px 10px 30px;
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12);

        div {
            font-family: $dm;
            .ImageName {
                font-size: 18px;
                margin-top: 5px;
                color: #0f1e3d;
                font-weight: 600;
            }
            .ImageDate {
                margin-top: 3px;
                margin-bottom: 15px;
                font-size: 12px;
                color: #3a4354;
            }

            .CloseButton {
                position: absolute;
                top: 20px;
                right: -10px;
                font-size: 20px;

                svg {
                    color: rgb(107, 110, 111);
                }

                &:hover {
                    svg {
                        cursor: pointer;
                        color: #1869ff;
                    }
                }
            }
        }

        .TaskImageContainer {
            margin: 0 auto;
            height: 90%;
            width: 98%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                border: 1px solid rgb(204, 200, 200);
                border-radius: 4px;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}
