@import "variables.scss";

.PasswordModal {
    // height: 120px;
    width: 280px;
    background-color: #fff;
    border-radius: 5px;
    border: 0.8px solid #e23e3e;
    padding: 15px;
    font-family: $dm;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 4px 0px, rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
    position: relative;
    z-index: 100;

    .LoadingSpinnerContainer,
    .CheckmarkContainer {
        position: absolute;
        top: 1%;
        left: 1%;
        width: 98%;
        height: 98%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .LoadingSpinnerContainer {
        background-color: rgba(255, 255, 255, 0.878);
        padding-bottom: 20px;
        padding-right: 15px;
    }
    .CheckmarkContainer {
        background-color: rgb(255, 255, 255);
        padding-bottom: 30px;
        padding-right: 10px;
        p {
            color: #fc7284;
            font-size: 14px;
            font-weight: 500;
            font-family: $dm;
            margin-top: 20px;
        }
    }
    h1 {
        font-size: 16px;
        font-weight: 500;
        color: #eb334c;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 12px;
        font-weight: 400;
        background-color: #fde3e7;
        padding: 8px;
        border-radius: 3px;
        margin-bottom: 10px;
    }

    label {
        display: block;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 12px;

        input {
            height: 30px;
            width: 100%;
            border: 0.5px solid rgb(198, 202, 208);
            border-radius: 3px;
            background: #f8fafa;
            margin-top: 4px;
            padding: 0 10px;
            color: #eb334c;
            font-family: $dm;
            outline: none;

            &:focus {
                border: 1.7px solid #eb334c;
            }
        }
    }
    .DeleteProject {
        width: 100%;
        text-align: center;
        background-color: #f15b6f;
        border: none;
        color: white;
        font-size: 13px;
        font-family: $gt;
        padding: 7px 60px;
        border-radius: 3px;
        margin: 0 auto;
        outline: none;
        cursor: pointer;

        &:hover {
            background-color: #eb334c;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
        }
    }
}
