@import "variables.scss";

.ProjectEntry {
    width: 100%;
    display: grid;
    grid-template-columns: 67% 29%;
    gap: 4%;
    margin-bottom: 0;
    height: calc(100% - 90px);

    .ColMain {
        display: flex;
        flex-direction: column;
        max-height: 100%;

        .NewTaskButton {
            outline: none;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
            margin-left: 15px;

            svg {
                margin: 0;
                width: auto;
                color: grey;
            }
            &:hover {
                svg {
                    color: #1869ff;
                }
            }
        }

        .Categories {
            padding-right: 20px;
            background-color: #d9dde4;
            border-radius: 2.5px;
            display: grid;
            grid-template-columns: 1fr 16fr 2fr 2fr;
            padding: 5px 8px;
            font-family: $dm;
            font-weight: 600;
            font-size: 12.5px;
            margin-bottom: 7px;
        }
        .TasksContainer {
            overflow: hidden;
            height: 500px;
        }
    }
    .ColSecondary {
        height: 100%;
        // background-color: rgb(0, 98, 255);
    }

    h1 {
        font-family: $gt;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1.1px;
        margin-bottom: 10px;
        height: 28px;
    }
}
