.LoadingRing {
    display: inline-block;
    position: relative;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        // width: 55px;
        // height: 55px;
        margin: 8px;
        // border: 4px solid #1cb6ee;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        // border-color: #1cb6ee transparent transparent transparent;
        z-index: 1002;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
