.HoverImage {
    &:hover {
        cursor: pointer;
        border-color: #35a9f163;
        transition: border-color 0.2s ease-in;
    }
}
.ImageAndBorderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 255, 0);

    .ImageContainer {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.571);
        overflow: hidden;
        border-radius: 50%;
        border: 0.5px solid white;

        p {
            text-align: center;
            margin-top: 10px;
            font-size: 9px;
        }

        img {
            object-fit: cover;
            height: 110%;
        }
    }
}
