@import "../../variables.scss";

.FormContainer {
    width: 650px;
    height: 550px;
    background-color: #f8fafa;
    padding: 50px 80px 55px 80px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(199, 205, 216, 0.5);
    // border: 0.2px solid #1869ffbb;
    border: 1px solid rgb(213, 217, 218);
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(85, 87, 91, 0.4);
    font-family: $gt;

    .LoadingSpinnerContainer {
        position: absolute;
        top: 2%;
        left: 5%;
        height: 90%;
        width: 90%;
        background-color: #f8fafaad;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CloseButton {
        position: absolute;
        top: 22px;
        right: -10px;
        font-size: 20px;

        svg {
            color: rgb(107, 110, 111);
        }

        &:hover {
            svg {
                cursor: pointer;
                color: #1869ff;
            }
        }
    }
    h4 {
        font-family: $dm;
        font-weight: 400;
        font-size: 14px;
        background-color: #e8edf1e0;
        padding: 12px 25px;
        margin: 10px 0;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    .ProjectDateWrapper {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 60px;
        font-size: 12px;

        h3 {
            font-size: 13px;
        }
        ul {
            margin-top: 10px;
            list-style: none;
            max-height: 140px;
            overflow-y: auto;

            li {
                position: relative;
                // margin: 2px 0;

                input[type="radio"] {
                    visibility: hidden;
                    position: absolute;
                }

                .SelectProject + label {
                    margin-bottom: 5px;
                    text-align: center;
                    height: 100%;
                    padding: 5px;
                    border-radius: 3px;
                    cursor: pointer;
                    border: 0.5px solid rgb(198, 202, 208);
                    background-color: white;

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                            rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                            rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
                        background-color: #9cd6ff;
                        transition: background-color 0.15s linear;
                        border-color: #5cbbff;
                    }
                }
                .SelectProject:checked + label {
                    background-color: #35a9f1;
                    color: white;
                    font-weight: 400;
                }
            }
        }

        label {
            display: block;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 12px;
            input {
                height: 30px;
                width: 100%;
                border: 0.5px solid rgb(198, 202, 208);
                border-radius: 3px;
                background: #f8fafa;
                margin-top: 4px;
                padding: 0 10px;
                color: #1869ff;
                font-family: $dm;
                outline: none;

                &:focus {
                    border: 1.7px solid #35a9f1;
                }
            }
        }
        .CreateButton {
            text-align: center;

            input[type="submit"] {
                background-color: #3180ee;
                border: none;
                color: white;
                font-size: 15px;
                font-family: $gt;
                padding: 7px 60px;
                border-radius: 3px;
                margin: 0 auto;
                outline: none;
                cursor: pointer;

                &:hover {
                    background-color: #5e9ef7;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
                        rgba(0, 0, 0, 0.05) 0px 1px 4px 0px,
                        rgba(0, 0, 0, 0.05) 0px 2px 8px 0px;
                }
            }
        }
    }
}
