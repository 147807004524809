// ---------------------------------------- COLORS
// 1. Light Mode
// 1.1 background color bcg-primary-l
$bcg-primary-l: #f7f9f9;
// 1.2 background color bcg-secondary-l
// 1.3 grey line
$line-primary-l: #dae0e8;
// 1.3 blue
$blue-l: #1869ff;
// 2. Dark Mode
// 2.1 background color bcg-primary-d
$bcg-primary-d: #1e2023;
// 2.2 background color bcg-secondary-d
$blue-d: #35a9f1;

// ---------------------------------------- FONTS
$os: "Open Sans";
$gt: "GT Walsheim Pro";
$dm: "DM Sans";
$po: "Poppins";
$ns: "Nunito Sans, sans-serif";

// ---------------------------------------- FONT-COLORS
$fc-primary-l: #6c767c;
$fc-secondary-l: #031b4e;
$fc-primary-d: #dae0e8;

svg {
    margin: 0;
}

// box-shadow: 0 2px 4px rgba(3, 27, 78, 0.9);
