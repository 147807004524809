@import "variables.scss";

.CardWrapper {
    display: flex;
    .Card {
        h4 {
            font-family: $gt;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            color: $fc-secondary-l;
        }
        .L {
            height: 4px;
            border-radius: 1px;
            width: 30px;
            background-color: $blue-l;
            margin-top: 4px;
            margin-bottom: 10px;
        }
        p {
            font-family: $dm;
            margin-bottom: 10px;
            font-size: 15px;

            span {
                color: $blue-l;
                font-family: $gt;
                font-size: 14px;
                font-weight: 700;
                margin-right: 5px;
            }
        }
    }
}
.DarkMode {
    .Card {
        h4 {
            color: rgb(141, 152, 157);
            font-weight: 400;
        }
        .L {
            background-color: $blue-l;
        }
        div {
            p {
                color: #848a91;
                font-weight: 100;
                font-size: 14.5px;
            }
            span {
                color: $blue-l;
            }
        }
    }
}
