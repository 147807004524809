@import "variables.scss";

.WelcomeWrapper {
    height: 100vh;
    padding: 3.5% 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .CookieModalWrapper {
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: #0a0b0f84;
        z-index: 999;

        .CookieModal {
            position: absolute;
            height: 320px;
            width: 360px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white;
            z-index: 1000;
            border-radius: 5px;
            padding: 45px 40px;
            font-family: $gt;
            color: rgb(17, 18, 22);
            font-size: 30px;
            box-shadow: rgba(19, 23, 29, 0.1) 0px 10px 50px 50px,
                rgba(13, 16, 22, 0.1) 0px 10px 10px 2px;
            font-weight: 600;
            line-height: 35px;

            a {
                color: #1869ff;
            }

            .Underline {
                border-bottom: 6px solid #1869ff;
            }
            .Text {
                margin-top: 30px;
                margin-bottom: 10px;
                color: rgba(44, 45, 52, 0.857);
                font-family: $dm;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
            .AddProject {
                margin-top: 20px;
                font-family: $gt;
                font-weight: 600;
                cursor: pointer;
                border-radius: 5px;
                background: white;
                transition: background 0.2s linear;
                outline: none;
                padding: 4px 10px;
                width: 145px;
                border: 1.5px solid #28292a;
                height: 32px;
                box-shadow: 3px 3px 1px #1869ff;
                transition: background 0.2s linear;

                &:hover {
                    color: #1869ff;
                    border-color: #1869ff;
                    transition: all 0.2s linear;
                }
            }
        }
    }

    .Welcome {
        width: 100%;
        padding-right: 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: context-menu;

        .Greeting {
            margin-top: -10px;
            font-family: $gt;
            color: $fc-secondary-l;
            font-size: 14px;
            align-self: flex-start;
            text-align: left;
        }

        .Heading {
            margin-top: 6%;
            display: flex;

            .WelcomeMessage {
                font-family: $po;
                h1 {
                    line-height: 37px;
                    color: $fc-secondary-l;

                    .MyName {
                        font-weight: 600;
                        position: relative;
                        cursor: pointer;
                        transition: border-color 0.3s ease-out;
                        padding: 0 5px;
                        border-radius: 3px;

                        &:hover {
                            color: black;
                            transition: border-color 0.3s ease-in;
                        }
                    }
                    .TooltipVisibleWhenHover {
                        pointer-events: auto !important;
                        color: #090f18;
                        padding: 10px;

                        &:hover {
                            visibility: visible !important;
                            opacity: 1 !important;
                        }

                        .TooltipContent {
                            max-width: 170px;
                            padding: 5px 0px;
                            display: flex;
                            justify-content: space-between;

                            p {
                                font-size: 13;
                                font-family: $dm;
                                font-weight: 400;
                                line-height: 15px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                h4 {
                    margin-top: 15px;
                    font-size: 12px;
                    font-weight: 500;
                    color: rgb(179, 183, 184);
                }
            }
            .ProfilePic {
                height: 180px;
                width: 180px;
                border-radius: 50%;
                border: 1px solid rgb(229, 226, 226);
                overflow: hidden;

                img {
                    width: 110%;
                }
            }
        }
        .Line {
            margin-top: 5%;
        }
        .CardsContainer {
            margin-top: 30px;

            p.Instructions {
                margin-top: 25px;
                font-family: $gt;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
            }
        }
        .AddProject {
            margin-top: 15px;
            font-family: $gt;
            font-weight: 500;
            cursor: pointer;
            border-radius: 5px;
            background: white;
            transition: background 0.2s linear;
            outline: none;
            padding: 4px 10px;
            width: 145px;
            border: 1.5px solid #28292a;
            height: 32px;
            box-shadow: 3px 3px 1px #1869ff;
            transition: background 0.2s linear;

            span {
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    font-size: 18px;
                    margin: 0 2px 1px 1px;
                    width: auto;
                }
            }

            &:hover {
                color: #1869ff;
                border-color: #1869ff;
                transition: all 0.2s linear;
            }
        }
    }

    .ChevIcon {
        position: absolute;
        bottom: 4%;
        left: 47%;

        svg {
            margin: 0;
            width: 30px;
            height: 30px;
            padding: 4px;
            border-radius: 50%;
            padding-top: 6px;
            transition: border-color 0.2s linear;
            border: 1px solid white;

            &:hover {
                border-color: #1869ff;
                transition: border-color 0.2s linear;
            }
        }
    }
}
.DarkMode {
    .Welcome {
        .Greeting {
            color: $fc-primary-d;
            font-weight: 300;
            letter-spacing: 0.6px;
        }

        .Heading {
            .WelcomeMessage {
                h1 {
                    color: $fc-primary-d;
                    font-weight: 200;

                    span {
                        font-weight: 500;
                    }
                }
                h4 {
                    color: #5b6367;
                }
            }
            .ProfilePic {
                border-color: $fc-secondary-l;
            }
        }
        .CardsContainer {
            p.Instructions {
                font-weight: 500;
                color: #bbc2c7;
            }
            .AddProject {
                background-color: #252a30;
                border-color: black;
                box-shadow: 3px 3px 1px black;
                color: #9aa0a9;

                svg {
                    fill: #9aa0a9;
                }
                &:hover {
                    color: $blue-d;
                    border-color: #35a9f1a3;
                    transition: all 0.2s linear;

                    svg {
                        fill: $blue-d;
                    }
                }
            }
        }
    }
    .ChevIcon {
        svg {
            border-color: black;

            &:hover {
                border-color: #35a9f1a3;
            }
        }
    }
}
